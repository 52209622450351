<template>
    <div v-loading.fullscreen.lock="loading">
        <staff-entry-search-bar-component
            add_auth="新增入职登记表"
            :AllEnterprise="AllEnterprise"
            @search="get_staff_entry_index"
            @addTeam="addTeam"
        ></staff-entry-search-bar-component>
        <common-table-component
            details_auth="入职登记表列表"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_staff_entry"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_staff_entry_index"
        ></common-page-component>
        <staff-entry-edit-component            
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :staff_entry_details_data="staff_entry_details_data"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            print_auth="打印入职登记表"
            :AllEnterprise="AllEnterprise"
            :AllRank="AllRank"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_staff_entry_index"
            @exitDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_staff_entry"
        ></staff-entry-edit-component>
    </div>
</template>

<script>
import { staff_entry_index_request,staff_entry_details_request} from '@/network/hrm/StaffEntry.js'
import { enterprise_list_request } from '@/network/list.js'

import StaffEntrySearchBarComponent from '@/components/hrm/StaffEntry/StaffEntrySearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import StaffEntryEditComponent from '@/components/hrm/StaffEntry/StaffEntryEditComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                entry_enterprise: '',
                staff_name: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [   
                {
                    prop: 'name',
                    label: '编号',
                    minWidth: '120px'
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'staff_name',
                    label: '姓名',
                    minWidth: '100px',
                },
                {
                    prop: 'entry_date',
                    label: '入职日期',
                    minWidth: '120px',
                },
                {
                    prop: 'entry_position_name',
                    label: '职位',
                    minWidth: '120px',
                },
                {
                    prop: 'entry_department_name',
                    label: '部门',
                    minWidth: '150px',
                },
                {
                    prop: 'entry_enterprise_name',
                    label: '企业',
                    minWidth: '220px',
                }
            ],
            staff_entry_details_data: {},
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false,
            AllEnterprise: [],
            AllRank: ['经理级别以下','经理及以上级别']
        }
    },
    computed:{},
    methods:{
        get_staff_entry_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.entry_enterprise = param.entry_enterprise ?? this.cond.entry_enterprise
            this.cond.staff_name = param.staff_name ?? this.cond.staff_name
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }
            staff_entry_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_staff_entry(id) {
            this.fid = id
            this.fdialogFormVisible = true
            staff_entry_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.staff_entry_details_data = s.result
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        frefresh() {
            this.details_staff_entry(this.fid)
            this.get_staff_entry_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人是否为自己
                if(res.create_staff === this.$store.state.user.id) {
                    this.to_approval_auth = this.$_has('提交入职登记表')
                    this.edit_auth = this.$_has('修改入职登记表')
                    this.del_auth = this.$_has('删除入职登记表')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if(res.create_staff === this.$store.state.user.id) {
                    this.withdraw_auth = this.$_has('撤回入职登记表')
                    this.add_file_auth = this.$_has('补充入职登记表附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批入职登记表')
                }
                this.sel_approval_auth = this.$_has('查看入职登记表审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充入职登记表附件')
                }
                this.sel_approval_auth = this.$_has('查看入职登记表审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if(res.create_staff === this.$store.state.user.id) {
                    this.to_approval_auth = this.$_has('提交入职登记表')
                    this.edit_auth = this.$_has('修改入职登记表')
                    this.del_auth = this.$_has('删除入职登记表')
                }
                this.sel_approval_auth = this.$_has('查看入职登记表审批记录')
            }
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.AllEnterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},

    props:{},
    created(){
        this.get_staff_entry_index()
        this.get_all_enterprise()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        StaffEntrySearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        StaffEntryEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>